<template>
    <div class="layout-footer">
        <div class="layout-width">

            <div class="footer-ul">
                <div class="footer-ul-title">
                    我的
                </div>
                <div class="footer-ul-item-box">
                    <a class="footer-item-a" target="_blank" href="/works"><div class="footer-a-item">我的创作</div></a>
                </div>
            </div>

            <div class="footer-ul">
                <div class="footer-ul-title">
                    创作
                </div>
                <div class="footer-ul-item-box">
                    
                    <!-- <a class="footer-item-a" target="_blank" href="/works"><div class="footer-a-item">护照识别方案</div></a> -->
                    <a class="footer-item-a" target="_blank" href="https://github.com/weivis/SmartParking"><div class="footer-a-item">基于汽车自动驾驶系统的智慧停车场方案</div></a>
                    <a class="footer-item-a" target="_blank" href="https://github.com/Shirley-WeiVi"><div class="footer-a-item">Shirley</div></a>
                </div>
            </div>

            <div class="footer-ul">
                <div class="footer-ul-title">
                    社区
                </div>
                <div class="footer-ul-item-box">
                    <a class="footer-item-a" target="_blank" href="https://github.com/weivis"><div class="footer-a-item">Github</div></a>
                    <a class="footer-item-a" target="_blank" href="https://gitee.com/weivi_admin"><div class="footer-a-item">Gitee</div></a>
                    <a class="footer-item-a" target="_blank" href="https://blog.csdn.net/sinat_34142727"><div class="footer-a-item">CSDN</div></a>
                    <a class="footer-item-a" target="_blank" href="https://www.zhihu.com/people/visang"><div class="footer-a-item">知乎</div></a>
                </div>
            </div>

            <div class="footer-ul">
                <div class="footer-ul-title">
                    动态
                </div>
                <div class="footer-ul-item-box">
                    <a class="footer-item-a" target="_blank" href="https://space.bilibili.com/4028423"><div class="footer-a-item">BiliBili</div></a>
                    <a class="footer-item-a" target="_blank" href="https://www.youtube.com/channel/UCBqmf7IgGFfyCfa9uqOIngg"><div class="footer-a-item">YouTube</div></a>
                    <a class="footer-item-a" target="_blank" href="https://weibo.com/u/5090946762"><div class="footer-a-item">Weibo</div></a>
                </div>
            </div>

            <div class="footer-ul">
                <div class="footer-ul-title">
                    联系方式
                </div>
                <div class="footer-ul-item-box">
                    <div class="footer-a-item">Email 442981412@qq.com</div>
                </div>
            </div>

        </div>
        <div class="layout-width">
            <div class="footer-lab">
                <div class="footer-lab-l">
                    <div>该网站是WeiVi的个人主页 感谢你的访问 遇到什么问题可以通过以上方式联系我</div>
                </div>
                <div class="footer-lab-r">
                    <div>Shirley-我一直在你身边 粤ICP备20033641号 © WeiVi 2014-2023</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
    };
  },
  methods:{
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.footer-ul{
    padding-top: 64px;
    height: 150px;
    width: 20%;
    float: left;
    .footer-ul-title{
        font-size: 14px;
        font-weight: 600;
    }
    .footer-ul-item-box{
        width: 80%;
        margin-top: 10px;
    }
    .footer-a-item{
        font-size: 14px;
        font-weight: 400;
        color: #000;
        margin-bottom: 5px;
    }
}
.footer-lab{
    // margin-top: 60px;
    // padding-top: 200px;
    width: 100%;
    font-size: 14px;
    .footer-lab-l{
        margin-top: 100px;
        text-align: left;
        width: 50%;
        float: left;
    }
    .footer-lab-r{
        margin-top: 100px;
        text-align: right;
        width: 50%;
        float: right;
    }
}
@media (max-width: 600px) {
.footer-ul{
    padding-top: 30px;
    height: auto;
    width: 100%;
    float: none;
    .footer-ul-title{
        font-size: 14px;
        font-weight: 600;
    }
    .footer-ul-item-box{
        width: 80%;
        margin-top: 10px;
    }
    .footer-a-item{
        font-size: 14px;
        font-weight: 400;
        color: #000;
        margin-bottom: 5px;
    }
}
.footer-lab{
    margin-top: 60px;
    width: 100%;
    font-size: 14px;
    .footer-lab-l{
        margin-top: 0px;
        width: 100%;
        float: none;
    }
    .footer-lab-r{
        text-align: center;
        margin-top: 30px;
        width: 100%;
        float: none;
        padding-bottom: 60px;
    }
}
}
</style>