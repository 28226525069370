<template>
    <div class="layout-header">
      <div class="layout-width">

        <div class="header-head" @click="oepnnav()">
          <img src="../assets/header/head.jpg" width="100%">
        </div>

        <div class="header-homename">WeiVi</div>

        <div class="header-nav" v-if="navop">
          <div v-for="(item, index) in nav" class="nav-item" :key="index + 'nav'" >
              <router-link :to="item.link">{{ item.title }}</router-link>
          </div>
          <div class="nav-item" >
            <a target="_black" href="https://github.com/Shirley-WeiVi">Shirley女友Ai化</a>
          </div>
          <div class="nav-item" >
            <a target="_black" href="https://github.com/weivis/SmartParking">基于汽车自动驾驶系统的智慧停车场方案</a>
          </div>
          <div class="nav-item-close" v-if="navopbutton == true" @click="oepnnav()">收起</div>
        </div>

        <div class="header-movepath" v-if="navop">
          <div v-for="(item, index) in movepath" class="header-movepath-icon" @click="openMovePath(item.link)" :key="index + 'movepath'">
            <img :src="item.icon" class="header-movepath-img"/>
          </div>
        </div>

      </div>
    </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      navop: true,
      navopbutton: false,
      screenWidth: document.body.clientWidth,
      nav: this.Common.L_Nav,
      movepath: this.Common.R_Nav
    };
  },
  created(){
      console.log('true')
      if(document.body.clientWidth > 850){
          this.navop = true
          this.navopbutton = false
      }else{
          this.navop = false
          this.navopbutton = true
      }
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function() {
          // 打印screenWidth变化的值
          if (that.screenWidth < 850) {
              that.navop = false
              that.navopbutton = true
          } else {
            that.navop = true;
            that.navopbutton = false
          }
          console.log(that.screenWidth);
          that.timer = false;
        }, 400);
      }
    }
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
  methods:{
    openMovePath(link){
      window.open(link,'_blank')
    },
    oepnnav(){
      if (this.navopbutton == true){
        if (this.navop == false){
            this.navop = true
        }else{
            this.navop = false
        }
      }
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>

.header-head{
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 12px;
  margin-left: 0px;
  overflow: hidden;
}

.header-homename{
  float: left;
  line-height: 64px;
  font-size: 18px;
  color: #ffffff;
}

.header-nav{
  float: left;
  overflow:auto;
}

.nav-item{
  margin-left: 30px;
  line-height: 64px;
  float: left;
  font-size: 14px;
  color: #ffffff;
  a{text-decoration:none;font-size: 14px;color: #ffffff;}
}

.header-movepath{
  float: right;
  position: relative;
}

.header-movepath-icon{
  width: 25px;
  height: 64px;
  line-height: 64px;
  position: relative;
  float: right;
  margin-left: 30px;
}

.header-movepath-img{
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}


@media (max-width: 600px) {
.header-nav{
  float: left;
}

.header-nav{
  position: absolute;
  float: left;
  left: 0;
  right: 0;
  background-color: #000000;
  top: 64px;
  overflow: hidden;
  padding-top: 64px;
}
.nav-item{
  width: 100%;
  margin-left: 30px;
  line-height: 64px;
  float: left;
  font-size: 14px;
  color: #ffffff;
  a{text-decoration:none;font-size: 14px;color: #ffffff;}
}
.nav-item-close{
  display: block;
  margin-left: 30px;
  line-height: 64px;
  float: left;
  font-size: 14px;
  color: #ffffff;
  border: 1px solid #b4b4b4;
  width: calc(100% - 100px);
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
  border-radius: 15px;
}
.header-movepath{
  position: absolute;
  left: 0px;
  top: 74px;
}
}
</style>