import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

import layout from '@/layout/Layout.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: layout,
    nav: true,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: { title: '主页'}
      },
      {
        path: 'works',
        name: 'Works',
        component: () => import('@/views/Home.vue'),
        meta: { title: '作品'}
      },
      {
        path: 'my',
        name: 'my',
        component: () => import('@/views/Home.vue'),
        meta: { title: '个人经历'}
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
