<script type="text/javascript">
    // 定义一些公共的属性和方法
    const L_Nav = [
                    {link:'/',title:'主页'},
                    {link:'/my',title:'个人经历'},
                    // {link:'/works',title:'护照识别方案'},
                ]

    const R_Nav = [
                    {link:'https://space.bilibili.com/4028423', icon:require('./assets/header/bilibili.png')},
                    {link:'https://www.youtube.com/channel/UCBqmf7IgGFfyCfa9uqOIngg', icon:require('./assets/header/youtube.png')},
                    {link:'https://twitter.com/WeiViViVi', icon:require('./assets/header/twitter.png')},
                    {link:'https://weibo.com/u/5090946762', icon:require('./assets/header/weibo.png')},
                    {link:'https://github.com/weivis', icon:require('./assets/header/github.png')},
                    {link:'https://qm.qq.com/cgi-bin/qm/qr?k=SjkChIxXvVXdkrxywX9_nBIEgv5zZeaM&jump_from=webapi&authKey=hhKDor9+EgXloA5uMO4pYXmx2YD9QdJkZzojTk0Zuwxe0GZkzdHLBDE20qHIwdXR', icon:require('./assets/header/qq.png')},
                ]

    export default {
        L_Nav,
        R_Nav
    }
</script>