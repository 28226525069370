import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/styles/main.scss' // global css
import Common from './Common'
import VueLive2d from 'vue-live2d-model' 

Vue.config.productionTip = false
Vue.prototype.Common = Common
Vue.use(VueLive2d)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
